import React from "react";
import Image, { FluidObject } from "gatsby-image";
import styles from "./Collage.module.scss";

type CollageItem = {
  image: FluidObject;
  alt: string;
};

type CollageProps = {
  images: CollageItem[];
};

const Collage = ({ images }: CollageProps) => {
  return (
    <div className={styles.collage}>
      {images.map(({ image, alt }, idx) => (
        <Image fluid={image} alt={alt} className={styles.img} key={idx} />
      ))}
    </div>
  );
};

export default Collage;
