import React, { Fragment } from "react";
import styles from "./ShopProductDetail.module.scss";
import { FluidObject } from "gatsby-image";

import { Container, Grid } from "@material-ui/core";
import { Hero } from "src/components";
import ShopProductGallery from "./ShopProductGallery";
import ProductVariationActions from "./ProductVariationActions";
import SpottedImages from "./SpottedImages";
import ShopSubscribeForm from "src/components/ShopProductListing/ShopSubscribeForm";
import {
  PrismicPageBodyShopPageListing,
  PrismicPageBodyShipping,
  PrismicPageBodyReturns
} from "src/graphqlTypes";
import ShopProductCard from "../ShopProductCard/ShopProductCard";

type ProductItemProps = {
  title: string;
  description: string;
  price: number;
  featuredImage: FluidObject;
  imageSrc: string;
  images: object;
  variants: object;
  inventory: string;
  tags: Array[string];
  totalInventory: string;
};

type MetaItemProps = {
  metafieldValue: object;
};

type RelatedProductsProps = {
  PopularProducts: object;
};

type ProductNode = {
  node: ProductItemProps;
};

type MetaNode = {
  node: MetaItemProps;
};

type RelatedProductsNode = {
  node: RelatedProductsProps;
};

type ProductsList = {
  productData: ProductNode;
  MetaData: MetaNode;
  RelatedProducts: RelatedProductsNode;
  data: PrismicPageBodyShopPageListing;
  shippingData: PrismicPageBodyShipping;
  returnData: PrismicPageBodyReturns;
  title?: string;
};

const ShopProductDetail = ({
  productData,
  MetaData,
  RelatedProducts,
  data,
  otherMeta
}: ProductsList) => {
  const products = RelatedProducts?.edges;

  return (
    <Hero id="proucts" size="large">
      <section className={styles.mainContent}>
        <Fragment>
          <div className={styles.items}>
            <Container>
              <Grid container spacing={2}>
                <div className={styles.dataBox}>
                  <ShopProductGallery
                    title={productData?.title}
                    image={productData?.featuredImage}
                    imageSrc={productData?.featuredImage?.transformedSrc}
                    images={productData?.images}
                  />
                  <ProductVariationActions
                    title={productData?.title}
                    description={productData?.descriptionHtml}
                    price={productData?.priceRangeV2?.minVariantPrice?.amount}
                    variants={productData?.variants}
                    inventory={productData?.totalInventory}
                    metafieldValue={MetaData?.edges}
                    product={productData}
                  />
                </div>
              </Grid>
              <Grid container spacing={2}>
                <SpottedImages
                  title={productData?.title}
                  description={productData?.descriptionHtml}
                  image={productData?.featuredImage}
                  imageSrc={productData?.featuredImage?.transformedSrc}
                  metafieldValue={MetaData?.edges}
                  shippingReturnsData={otherMeta}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} className={styles.marginBtm}>
                  <h1 className={styles.mainHeading}>You may also like</h1>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={styles.marginBtm}>
                {products.map(popProduct => {
                  const ProDetail = popProduct.node;
                  if (
                    JSON.stringify(ProDetail.tags) ==
                    JSON.stringify(productData.tags)
                  ) {
                    return (
                      <Grid item lg={4}>
                        <ShopProductCard
                          title={ProDetail?.title}
                          description={ProDetail?.descriptionHtml}
                          image={ProDetail?.featuredImage}
                          price={
                            ProDetail?.priceRangeV2?.minVariantPrice?.amount
                          }
                          imageSrc={ProDetail?.featuredImage?.transformedSrc}
                          totalInventory={ProDetail?.totalInventory}
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Container>
          </div>
        </Fragment>
      </section>
      <ShopSubscribeForm
        title={data?.primary?.stay_updated_title?.text}
        text={data?.primary?.stay_updated_text?.text}
      />
    </Hero>
  );
};

export default ShopProductDetail;
