import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./ShopProductsList.module.scss";
import { encodeFormData } from "src/utils/encodeFormData";
import {
  Typography,
  Button,
  TextField,
  Box,
  useTheme,
  Container
} from "@material-ui/core";

type ShopSubscribeForm = {
  title: string;
  text: string;
};

const ShopSubscribeForm = ({ title, text }: ShopSubscribeForm) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const { handleSubmit, register, errors, reset } = useForm();
  const onSubmit = async data => {
    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeFormData({
          "form-name": "subscribe-shop",
          ...data
        })
      });

      if (response.ok) {
        setSuccess(true);
        reset();
      } else {
        console.error("Request failed", response);
        setError(
          "There was an error submitting your message, please try again."
        );
      }
    } catch (e) {
      console.error(e);
      setError("Something went wrong, please try again.");
    }
  };

  const theme = useTheme();
  return (
    <div className={styles.form__wrapper}>
      <Container>
        {success && (
          <Box
            bgcolor={theme.palette.success.main}
            color="white"
            p={2}
            mb={2}
            maxWidth="600px"
          >
            <Typography>You've signed up!</Typography>
          </Box>
        )}
        {error && (
          <Box
            bgcolor={theme.palette.error.main}
            color="white"
            p={2}
            mb={2}
            maxWidth="600px"
          >
            <Typography>{error}</Typography>
          </Box>
        )}
        <Typography variant="h2" className={styles.form_title}>
          {title}
        </Typography>
        <Typography variant="body2" className={styles.form_description}>
          {text}
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          name="subscribe-shop"
          netlify-honeypot="bot-field"
          data-netlify="true"
          className={styles.section_form}
        >
          <TextField
            type="email"
            variant="outlined"
            label="Email"
            name="email"
            margin="normal"
            inputRef={register({ required: true })}
            error={!!errors?.email}
            helperText={
              errors?.email?.type === "required" && "Email is required"
            }
            className={styles.section_input}
          />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            className={styles.section_button}
          >
            Submit
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default ShopSubscribeForm;
