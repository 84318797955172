import React, { useEffect, useState } from "react";
import styles from "./ProductItem.module.scss";
import Image, { FluidObject } from "gatsby-image";
import { Typography, useTheme } from "@material-ui/core";

type ProductItemProps = {
  title: string;
  description: string;
  price: number;
  image: FluidObject;
  imageAlt: string;
  badgeType?: string;
};

const ProductItem = ({
  title,
  description,
  price,
  image,
  imageAlt,
  badgeType
}: ProductItemProps) => {
  const theme = useTheme();

  const badges = {
    CHEFS_PICK: {
      title: "Chef's Pick",
      color: theme.palette.primary.main
    },
    VEGETARIAN: {
      title: "Vegetarian",
      color: theme.palette.success.main
    }
  };

  return (
    <div className={styles.item}>
      <Image fluid={image} alt={imageAlt} className={styles.image} />

      <div className={styles.title_container}>
        <Typography variant="h6" component="p">
          <strong>{title}</strong>
        </Typography>

        {badgeType && (
          <div
            className={styles.badge}
            style={{ borderColor: badges[badgeType].color }}
          >
            <Typography variant="caption" component="p">
              <strong>{badges[badgeType].title}</strong>
            </Typography>
          </div>
        )}
      </div>

      <Typography variant="h6" component="p" gutterBottom>
        <strong>${price}</strong>
      </Typography>

      <Typography
        className={styles.description}
        variant="body1"
        component="p"
        color="textSecondary"
        paragraph
      >
        {description}
      </Typography>
    </div>
  );
};

export default ProductItem;
