import React from "react";
import { Hero } from "src/components";
import {
  Container,
  Divider,
  Link,
  Grid,
  Box,
  Typography
} from "@material-ui/core";
import { Link as GatsbyLink, graphql, useStaticQuery } from "gatsby";
import { Facebook, Twitter, Instagram } from "@material-ui/icons";
import styles from "./Footer.module.scss";
import ReviewButtonSvg from "src/images/google_review.svg";
import { PrismicContactInfo, PrismicDownloads } from "src/graphqlTypes";

// Helper function to create equal columns
const chunk = (target, size) => {
  return target.reduce(
    (memo, value, index) => {
      if (index % (target.length / size) === 0 && index !== 0) memo.push([]);
      memo[memo.length - 1].push(value);
      return memo;
    },
    [[]]
  );
};

const Footer = () => {
  const {
    prismicContactInfo,
    prismicDownloads
  }: {
    prismicContactInfo: PrismicContactInfo;
    prismicDownloads: PrismicDownloads;
  } = useStaticQuery(query);

  const Links = [
    { title: "Home", path: "/" },
    { title: "Press", path: "/press" },
    { title: "Contact", path: "/contact" },
    { title: "Catering", path: "/catering" },
    { title: "Story", path: "/story" },
    { title: "Privacy Policy", path: "/privacy" },
    { title: "Terms of Service", path: "/tos" },
    { title: "Report an Issue", path: "/report-an-issue" },
    {
      title: "Press Kit",
      path: prismicDownloads.data.press_kit_url.url,
      external: true
    },
    { title: "", path: "" }
  ];

  return (
    <footer>
      <Hero color="#222023">
        <Hero.Body>
          <Container>
            <div className={styles.footer_nav}>
              {chunk(Links, 2).map((c, idx) => (
                <ul key={idx}>
                  {c.map(({ title, path, external }, idx) => (
                    <li key={idx}>
                      {external ? (
                        <Link
                          color="inherit"
                          href={path}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {title}
                        </Link>
                      ) : (
                        <Link color="inherit" component={GatsbyLink} to={path}>
                          {title}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              ))}

              <div className={styles.google_review_image}>
                <Link href={prismicContactInfo.data.reviews_url.url}>
                  <img src={ReviewButtonSvg} alt="Leave a review on Google!" />
                </Link>
              </div>
            </div>
          </Container>
        </Hero.Body>

        <Hero.Footer>
          <Divider style={{ background: "rgba(232, 232, 232, 0.1)" }} />

          <Box my={2} className={styles.footer_masthead}>
            <Container>
              <Grid container alignItems="center">
                <Grid item xs={12} md={6} className={styles.social}>
                  <div className={styles.social_icons}>
                    <Link
                      href={prismicContactInfo.data.facebook_url.url}
                      rel="noopener noreferrer"
                      target="_blank"
                      color="inherit"
                      aria-label="Detroit 75 Kitchen - Facebook"
                    >
                      <Facebook />
                    </Link>

                    <Link
                      href={prismicContactInfo.data.twitter_url.url}
                      rel="noopener noreferrer"
                      target="_blank"
                      color="inherit"
                      aria-label="Detroit 75 Kitchen - Twitter"
                    >
                      <Twitter />
                    </Link>

                    <Link
                      href={prismicContactInfo.data.instagram_url.url}
                      rel="noopener noreferrer"
                      target="_blank"
                      color="inherit"
                      aria-label="Detroit 75 Kitchen - Instagram"
                    >
                      <Instagram />
                    </Link>
                  </div>

                  <div className={styles.halal_icon}>Halal Certified</div>
                </Grid>

                <Grid item xs={12} md={6} className={styles.copyright}>
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: "rgba(255, 255, 255, 0.67)" }}
                  >
                    &copy; {new Date().getFullYear()} Detroit 75 Kitchen | All
                    rights reserved.
                  </Typography>

                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: "rgba(255, 255, 255, 0.67)" }}
                  >
                    This website was customly designed and engineered by the
                    Detroit 75 Kitchen Team.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Hero.Footer>
      </Hero>
    </footer>
  );
};

const query = graphql`
  query {
    prismicContactInfo {
      data {
        facebook_url {
          url
        }
        twitter_url {
          url
        }
        instagram_url {
          url
        }
        reviews_url {
          url
        }
      }
    }
    prismicDownloads {
      data {
        press_kit_url {
          url
        }
      }
    }
  }
`;

export default Footer;
