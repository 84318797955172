import React, { Fragment } from "react";
import styles from "./ShopProductsList.module.scss";
import { FluidObject } from "gatsby-image";
import { Container } from "@material-ui/core";
import { ShopProductCard, Hero } from "src/components";
import { PrismicPageBodyShopPageListing } from "src/graphqlTypes";
import ShopSubscribeForm from "./ShopSubscribeForm";

type SelectedOption = {
  name: string;
  value: string;
};

type VariantProps = {
  id: string;
  weight: string;
  sku: string;
  availableForSale: boolean;
  inventoryQuantity: string;
  selectedOptions: SelectedOption[];
};

type VariantsProps = {
  variants: VariantProps[];
};

type ProductItemProps = {
  title: string;
  totalVariants: string;
  description: string;
  price: number;
  featuredImage: FluidObject;
  imageSrc: string;
  totalInventory: string;
  tags: Array[string];
  variants: VariantsProps[];
};

type ProductNode = {
  node: ProductItemProps;
};

type ProductsList = {
  products: ProductNode[];
  title?: string;
  data: PrismicPageBodyShopPageListing;
};

const ShopProductsList = ({ products, title, data }: ProductsList) => {
  const categorySet = new Set();
  products.forEach(product => {
    product.node.tags[0] && categorySet.add(product.node.tags[0]);
  });
  function compare(a, b) {
    if (a.category_order.text < b.category_order.text) {
      return -1;
    }
    if (a.category_order.text > b.category_order.text) {
      return 1;
    }
    return 0;
  }
  const sortedProductsCategories = data.items.sort(compare);

  return (
    <Hero id="proucts" size="large">
      <Hero.Body>
        <Container>
          <h2 className={styles.page_title}>{data?.primary?.title1?.text}</h2>
          <Fragment>
            {sortedProductsCategories.length &&
              sortedProductsCategories.map(category => {
                return (
                  <div className={styles.category_row}>
                    <h2 className={styles.category_title}>
                      {category.category_name.text}
                    </h2>
                    <div className={styles.product__grid}>
                      {products
                        .filter(
                          product =>
                            product.node.tags[0] &&
                            product.node.tags[0] === category.category_name.text
                        )
                        .map(product => {
                          return (
                            <ShopProductCard
                              title={product?.node?.title}
                              description={product?.node?.descriptionHtml}
                              image={product?.node?.featuredImage}
                              price={
                                product?.node?.priceRangeV2?.minVariantPrice
                                  ?.amount
                              }
                              imageSrc={
                                product?.node?.featuredImage?.transformedSrc
                              }
                              key={product?.node?.title}
                              variants={product?.node?.variants}
                              totalInventory={product?.node?.totalInventory}
                            />
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </Fragment>
        </Container>
      </Hero.Body>
      <ShopSubscribeForm
        title={data?.primary?.stay_updated_title?.text}
        text={data?.primary?.stay_updated_text?.text}
      />
    </Hero>
  );
};

export default ShopProductsList;
