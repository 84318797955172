import React, { useState } from "react";
import styles from "./SubscribeAvailability.module.scss";
import { useForm } from "react-hook-form";
import { encodeFormData } from "src/utils/encodeFormData";
import {
  Typography,
  Button,
  TextField,
  Box,
  useTheme
} from "@material-ui/core";

const SubscribeAvailability = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const { handleSubmit, register, errors, reset } = useForm();
  const onSubmit = async data => {
    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeFormData({
          "form-name": "subscribe-product-availability",
          ...data
        })
      });

      if (response.ok) {
        setSuccess(true);
        reset();
      } else {
        console.error("Request failed", response);
        setError(
          "There was an error submitting your message, please try again."
        );
      }
    } catch (e) {
      console.error(e);
      setError("Something went wrong, please try again.");
    }
  };

  const theme = useTheme();
  return (
    <div className={styles.form__wrapper}>
      {success && (
        <Box bgcolor={theme.palette.success.main} color="white" p={1}>
          <Typography variant="body2">You’ve signed up!</Typography>
        </Box>
      )}
      {error && (
        <Box bgcolor={theme.palette.error.main} color="white" p={1}>
          <Typography variant="body2">{error}</Typography>
        </Box>
      )}
      <Typography variant="h6" component="p" className={styles.form_title}>
        Drop your email below to hear about future merch jobs
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
        name="subscribe-product-availability"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <TextField
          type="email"
          variant="outlined"
          label="Email"
          name="email"
          margin="normal"
          inputRef={register({ required: true })}
          error={!!errors?.email}
          helperText={errors?.email?.type === "required" && "Email is required"}
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default SubscribeAvailability;
