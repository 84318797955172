import React, { Fragment, useState, useContext } from "react";
import styles from "./ShopProductDetail.module.scss";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import isEqual from "lodash.isequal";

import { AddToCart } from "../AddToCart/AddToCart";

import { Close } from "@material-ui/icons";
import ProductAddToCart from "./ProductAddToCart";
import { StoreContext } from "../../context/store-context";
import SubscribeAvailability from "../SubscribeAvailability/SubscribeAvailability";

import ScaleImg from "../../../src/images/scale.png";

type ProductsList = {
  title: string;
  description: string;
  price: string;
  inventory: number;
  metafieldValue: object;
  product: object;
  totalInventory: number;
};

const ProductVariationActions = ({
  title,
  description,
  price,
  inventory,
  metafieldValue,
  product
}: ProductsList) => {
  const {
    variants,
    variants: [initialVariant],
    options
  } = product;

  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(0);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const arrayDescription = description.split("<br>");
  const priceInteger = price.split(".0");
  const metaField = metafieldValue[0].node;
  const metaFieldRec = metaField.metafields;
  let size_guide_image = new Array();

  {
    metaFieldRec != null &&
      metaFieldRec.map((metaFieldVal, idx) => {
        if (metaFieldVal.key == "size_guide_image") {
          const images = metaFieldVal.value;
          size_guide_image.push(images);
        }
      });
  }

  const { client } = useContext(StoreContext);
  const [variant, setVariant] = useState({ ...initialVariant });

  const [quantity, setQuantity] = useState(1);
  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailablity = React.useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.storefrontId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.storefrontId, client.product]
  );

  const setVariantProduct = (event, index, name) => {
    const value = event.target.value;
    setChecked(index);
    if (value === "") {
      return;
    }

    const currentOptions = [
      {
        name: name,
        value: value
      }
    ];

    const selectedVariant = variants.find(variant => {
      return isEqual(currentOptions, variant.selectedOptions);
    });
    setVariant({ ...selectedVariant });
  };

  React.useEffect(() => {
    checkAvailablity(product.storefrontId);
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId]);

  const hasVariants = variants.length > 1;

  return (
    <div className={styles.dataDesc}>
      <div className={styles.title_container}>
        <Typography
          variant="h6"
          component="p"
          onClick={() => navigate(`${slugify(title)}`)}
        >
          <strong>{title}</strong>
        </Typography>
      </div>
      <Typography variant="h6" component="p" gutterBottom>
        <strong>${(options && options.variation) || priceInteger}</strong>
      </Typography>
      <Typography className={styles.description}>
        <p>{arrayDescription[0]}</p>
      </Typography>
      {variants.length == 1 ? (
        ""
      ) : (
        <>
          <div className={styles.sizeBox}>
            <p className={styles.textClr}>{options[0].name}</p>
            <Fragment>
              {hasVariants &&
                options.map(({ id, name, values }, index) => {
                  return (
                    <div key={id}>
                      {values.map((value, index) => {
                        return (
                          <div className={styles.sizeBtn}>
                            <input
                              type="radio"
                              className={styles.sizeRadio}
                              name="Size"
                              value={value}
                              checked={index === checked}
                              onChange={event =>
                                setVariantProduct(event, index, name)
                              }
                            />
                            <label className={styles.sizeLabel}>{value}</label>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </Fragment>

            <p
              style={{ cursor: "pointer" }}
              className={styles.marginTop10}
              onClick={handleClickOpen}
            >
              Size Guide
              <img src={ScaleImg} alt="Size Guide" />
            </p>
            <div>
              <Dialog
                className="daillogBox"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
              >
                <p
                  style={{
                    textAlign: "right",
                    paddingRight: "15px",
                    cursor: "pointer"
                  }}
                >
                  <Close onClick={handleClose} />
                </p>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <div className="">
                      <img
                        src={size_guide_image && size_guide_image}
                        alt={title}
                        className={styles.image}
                      />
                    </div>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </>
      )}
      {inventory !== 0 ? (
        <ProductAddToCart
          available={available}
          quantity={quantity}
          variantId={productVariant.storefrontId}
        />
      ) : (
        <SubscribeAvailability />
      )}
    </div>
  );
};

export default ProductVariationActions;
