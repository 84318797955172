import React from "react";
import styles from "./ShopProductDetail.module.scss";
import ProductAccordion from "./ProductAccordion";

type SpottedImage = {
  title: string;
  description: string;
  image: string;
  imageSrc: string;
  metafieldValue: string;
  shippingReturnsData: string;
};

const SpottedImages = ({
  title,
  description,
  image,
  imageSrc,
  metafieldValue,
  shippingReturnsData
}: SpottedImage) => {
  const metaField = metafieldValue[0].node;
  const metaFieldRec = metaField.metafields;
  let spotted_img = new Array();

  {
    metaFieldRec != null &&
      metaFieldRec.map((metaFieldVal, idx) => {
        if (metaFieldVal.key == "spotted_images") {
          const image = metaFieldVal.value;
          spotted_img.push(image);
        }
      });
  }
  return (
    <div className={styles.desDataBox}>
      <div className={styles.dataImg}>
        <div className={styles.productImgSlide}>
          <img
            src={(spotted_img.length && spotted_img) || imageSrc}
            alt={title}
            className={styles.image}
          />
        </div>
        <p className={styles.spottedText}>Spotted on</p>
      </div>
      <ProductAccordion
        title={title}
        description={description}
        image={image}
        imageSrc={imageSrc}
        metafieldValue={metafieldValue}
        shippingReturnsData={shippingReturnsData}
      />
    </div>
  );
};

export default SpottedImages;
