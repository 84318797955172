import React from "react";
import styles from "./ShopProductDetail.module.scss";
import { Button } from "@material-ui/core";
import { AddToCart } from "../AddToCart/AddToCart";
import { StoreContext } from "../../context/store-context";

const ProductAddToCart = ({ variantId, quantity, available }) => {
  const { addVariantToCart, loading, checkout } = React.useContext(
    StoreContext
  );

  function buyNow(e) {
    e.preventDefault();
    addVariantToCart(variantId, quantity);

    setTimeout(() => {
      window.open(checkout.webUrl);
    }, 1000);
  }
  return (
    <div className={styles.marginBtm}>
      <AddToCart
        variantId={variantId}
        quantity={quantity}
        available={available}
      />
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        className={styles.darkBtn}
        style={{
          height: "100%",
          textDecoration: "none"
        }}
        onClick={buyNow}
      >
        BUY IT NOW
      </Button>
    </div>
  );
};

export default ProductAddToCart;
