import React, { HTMLAttributes } from "react";
import { FluidObject } from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import clsx from "clsx";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import styles from "./Hero.module.scss";
import { useTheme } from "@material-ui/core";

type HeroProps = {
  /** Hero sizes
   * @default medium
   */
  size?: "mini" | "small" | "medium" | "large";
  /** Hero background colors (inheirts colors from theme)
   * @default inherit
   */
  color?: string;
  /** Set the minimum height of the hero to 100vh
   * @default false
   */
  fullHeight?: boolean;
  /**
   * Set a background image
   */
  image?: FluidObject;
  /**
   * Controls image tint opacity
   */
  tintOpacity?: number;
  children?: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const defaultProps: HeroProps = {
  size: "medium",
  color: "#fff",
  fullHeight: false
};

const Hero = ({
  size,
  color,
  fullHeight,
  image,
  tintOpacity,
  children,
  ...props
}: HeroProps) => {
  const theme = useTheme();

  const colorStyles = {
    color: !["primary", "secondary", null].includes(color)
      ? theme.palette.getContrastText(color)
      : null,
    backgroundColor: !["primary", "secondary"].includes(color) ? color : null
  };

  const heroClassNames = clsx(styles.hero, {
    [styles.hero__color_primary]: color === "primary",
    [styles.hero__color_secondary]: color === "secondary",
    [styles.hero__size_mini]: size === "mini",
    [styles.hero__size_small]: size === "small",
    [styles.hero__size_medium]: size === "medium",
    [styles.hero__size_large]: size === "large",
    [styles.hero__fullHeight]: fullHeight === true
  });

  return (
    <section className={heroClassNames} style={colorStyles} {...props}>
      {image ? (
        <BackgroundImage fluid={image} className={styles.hero__image}>
          <div className={styles.hero__image_tinted} />
          {children}
        </BackgroundImage>
      ) : (
        children
      )}
    </section>
  );
};

Hero.Header = Header;
Hero.Body = Body;
Hero.Footer = Footer;

Hero.defaultProps = defaultProps;

export default Hero;
