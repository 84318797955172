import React from "react";
import Modal from "react-modal";
import { motion } from "framer-motion";
import { useEffect } from "react";
import styles from "./MobileNav.module.scss";
import navStyles from "./Nav.module.scss";
import { Container, Fab, Link, Typography, Button } from "@material-ui/core";
import { Close, ShoppingCartOutlined } from "@material-ui/icons";
import { Link as GatsbyLink } from "gatsby";

Modal.setAppElement("#___gatsby");

type MobileNavProps = {
  open: boolean;
  handleClick: () => void;
  logoSrc: string;
  logoAlt: string;
  orderUrl: string;
  merchUrl?: string;
  merchUrlTitle?: string;
  cateringUrl?: string;
  shopEnabled: boolean;
  merchLinkEnabled: boolean;
};

const defaultProps: Partial<MobileNavProps> = {
  open: false
};

const MobileNav = ({
  open,
  handleClick,
  logoSrc,
  logoAlt,
  orderUrl,
  merchUrl,
  merchUrlTitle,
  cateringUrl,
  shopEnabled,
  merchLinkEnabled
}: MobileNavProps) => {
  useEffect(() => {
    if (document) {
      document.querySelector("body").style.overflow = open
        ? "hidden"
        : "visible";
    }
  }, [open]);

  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 }
  };

  const itemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };
  console.log(merchUrl);
  return (
    <Modal
      isOpen={open}
      shouldReturnFocusAfterClose={false}
      closeTimeoutMS={500}
      style={{
        overlay: {
          display: "flex",
          flex: 1,
          background: "transparent",
          margin: 0,
          padding: 0,
          zIndex: 500
        },
        content: {
          position: "relative",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          flex: 1,
          margin: 0,
          padding: 0,
          background: "transparent",
          border: "none",
          borderRadius: 0
        }
      }}
    >
      <motion.nav
        initial="closed"
        animate={open ? "open" : "closed"}
        variants={variants}
        transition={{ duration: 0.5, staggerChildren: 0.85 }}
        className={styles.modal__container}
      >
        <div className={navStyles.nav}>
          <Container className={navStyles.nav__toolbar}>
            <div className={navStyles.nav__logo}>
              <GatsbyLink to="/">
                <img src={logoSrc} alt={logoAlt} />
              </GatsbyLink>
            </div>

            <div className={navStyles.nav__actions}>
              <Fab
                size="small"
                color="primary"
                className={navStyles.nav__menuButton}
                onClick={handleClick}
                aria-label="Close Menu"
              >
                <Close />
              </Fab>
            </div>
          </Container>
        </div>

        <Container className={styles.modal__content}>
          <motion.div
            className={styles.nav__links}
            variants={{
              open: {
                transition: { staggerChildren: 0.07, delayChildren: 0.2 }
              },
              closed: {
                transition: {
                  staggerChildren: 0.05,
                  staggerDirection: -1
                }
              }
            }}
          >
            <ul>
              <motion.li
                variants={itemVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <Typography variant="h3" component="div">
                  <Link
                    color="inherit"
                    component={GatsbyLink}
                    to="/"
                    activeClassName={styles.nav__link_active}
                  >
                    <strong>Home</strong>
                  </Link>
                </Typography>
              </motion.li>
              {shopEnabled && (
                <motion.li
                  variants={itemVariants}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Typography variant="h3" component="div">
                    <Link
                      color="inherit"
                      component={GatsbyLink}
                      to="/shop"
                      activeClassName={styles.nav__link_active}
                    >
                      <strong>Shop</strong>
                    </Link>
                  </Typography>
                </motion.li>
              )}

              <motion.li
                variants={itemVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <Typography variant="h3" component="div">
                  <Link
                    color="inherit"
                    component={GatsbyLink}
                    to="/story"
                    activeClassName={styles.nav__link_active}
                  >
                    <strong>Story</strong>
                  </Link>
                </Typography>
              </motion.li>

              {merchLinkEnabled && (
                <motion.li
                  variants={itemVariants}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Typography variant="h3" component="div">
                    <Link
                      color="inherit"
                      component={GatsbyLink}
                      to={merchUrl}
                      activeClassName={styles.nav__link_active}
                    >
                      <strong>{merchUrlTitle}</strong>
                    </Link>
                  </Typography>
                </motion.li>
              )}
              {cateringUrl && (
                <motion.li
                  variants={itemVariants}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Typography variant="h3" component="div">
                    <Link
                      color="inherit"
                      component={GatsbyLink}
                      to={cateringUrl}
                      activeClassName={styles.nav__link_active}
                    >
                      <strong>Catering</strong>
                    </Link>
                  </Typography>
                </motion.li>
              )}
              <motion.li
                variants={itemVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <Typography variant="h3" component="div">
                  <Link
                    color="inherit"
                    component={GatsbyLink}
                    to="/press"
                    activeClassName={styles.nav__link_active}
                  >
                    <strong>Press</strong>
                  </Link>
                </Typography>
              </motion.li>

              <motion.li
                variants={itemVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <Typography variant="h3" component="div">
                  <Link
                    color="inherit"
                    component={GatsbyLink}
                    to="/contact"
                    activeClassName={styles.nav__link_active}
                  >
                    <strong>Contact</strong>
                  </Link>
                </Typography>
              </motion.li>

              {shopEnabled && (
                <motion.li
                  variants={itemVariants}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Typography variant="h3" component="div">
                    <Link
                      color="inherit"
                      component={GatsbyLink}
                      to="/cart"
                      activeClassName={styles.nav__link_active}
                    >
                      <ShoppingCartOutlined />
                    </Link>
                  </Typography>
                </motion.li>
              )}
              <motion.li variants={itemVariants}>
                <Button
                  href={orderUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={styles.order_button}
                >
                  Order Now
                </Button>
              </motion.li>
            </ul>
          </motion.div>
        </Container>
      </motion.nav>
    </Modal>
  );
};

MobileNav.defaultProps = defaultProps;

export default MobileNav;
