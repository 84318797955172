import * as React from "react";
import { StoreContext } from "../../context/store-context";
import styles from "./Styles.module.scss";
import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
export function AddToCart({ variantId, quantity, available, ...props }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext);
  const [message, setMessage] = React.useState(false);
  const handleClose = () => {
    setMessage();
  };
  function addToCart(e) {
    e.preventDefault();
    addVariantToCart(variantId, quantity);
    setMessage("Product has been added to the cart successfully!");
  }
  return (
    <>
      {message && (
        <span className={styles.successMsg}>
          {message}
          <Close className={styles.closeMsg} onClick={handleClose} />
        </span>
      )}
      <Button
        type="submit"
        variant="outlined"
        color="secondary"
        className={styles.customBtn}
        onClick={addToCart}
        disabled={!available || loading}
        {...props}
      >
        {available ? "Add to Cart" : "Out of Stock"}
      </Button>
    </>
  );
}
