import React from "react";
import styles from "./ShopProductDetail.module.scss";
import {
  Typography,
  useTheme,
  AccordionDetails,
  AccordionSummary,
  Accordion
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

type SpottedImage = {
  shippingReturnsData: Object;
  description: string;
};

const ProductAccordion = ({
  description,
  shippingReturnsData
}: SpottedImage) => {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={styles.dataDesc}>
      <div>
        <Accordion
          className={styles.marginBtm20}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={styles.boldText}>Product Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              className={styles.description}
              variant="body1"
              component="p"
              color="textSecondary"
              paragraph
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        {shippingReturnsData.map((records, idx) => {
          switch (records.__typename) {
            case "PrismicPageBodyShipping":
              return (
                <Accordion
                  className={styles.marginBtm20}
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={styles.boldText}>
                      {records?.primary?.title1?.text}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {records?.primary?.shiiping_description?.text}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            case "PrismicPageBodyRetruns":
              return (
                <Accordion
                  className={styles.marginBtm20}
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={styles.boldText}>
                      {records?.primary?.ttile?.text}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {records?.primary?.retrun_description.text}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
          }
        })}
      </div>
    </div>
  );
};

export default ProductAccordion;
