import React from "react";
import styles from "./ProductItem.module.scss";
import { FluidObject } from "gatsby-image";
import { Link } from "gatsby";
import { Typography } from "@material-ui/core";
import { GatsbyImage } from "gatsby-plugin-image";
import SubscribeAvailability from "../SubscribeAvailability/SubscribeAvailability";

const slugify = require("@sindresorhus/slugify");

type SelectedOption = {
  name: string;
  value: string;
};

type ProductItemProps = {
  title: string;
  description: string;
  price: number;
  image: FluidObject;
  imageSrc: string;
  totalInventory: number;
};

const ShopProductCard = ({
  title,
  description,
  price,
  image,
  imageSrc,
  totalInventory
}: ProductItemProps) => {
  const slug = slugify(title);
  let availableForSale;
  if (totalInventory === 0) {
    availableForSale = "not-available";
  }
  const arrayDescription = description.split("<br>");
  const priceInteger = price.split(".0");
  return (
    <div
      className={availableForSale === "not-available" ? styles.notAvailabe : ""}
    >
      <div className={styles.item}>
        <Link to={`/shop/${slug}`}>
          <GatsbyImage image={image?.gatsbyImageData} alt={title} />
          {availableForSale === "not-available" && (
            <div className={styles.badgeSoldOut}>Sold Out</div>
          )}
        </Link>
        <div className={styles.title_container}>
          <Typography variant="h6" component="p">
            <Link to={`/shop/${slug}`}>
              <strong>{title}</strong>
            </Link>
          </Typography>
        </div>

        <Typography variant="h6" component="h5" gutterBottom>
          <strong>${priceInteger}</strong>
        </Typography>
        <Typography
          className={styles.description}
          variant="body1"
          component="p"
          color="textSecondary"
          paragraph
        >
          {arrayDescription[0]}
        </Typography>
        {availableForSale === "not-available" && <SubscribeAvailability />}
      </div>
    </div>
  );
};

export default ShopProductCard;
