import React, { useState } from "react";
import { Container, Fab, Link, Button, Hidden } from "@material-ui/core";
import {
  Menu,
  Facebook,
  Twitter,
  Instagram,
  Phone,
  Send,
  ShoppingCartOutlined
} from "@material-ui/icons";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";
import { PrismicSite, PrismicContactInfo } from "src/graphqlTypes";
import { formatPhoneNumber } from "src/utils/formatPhoneNumber";
import MobileNav from "./MobileNav";
import styles from "./Nav.module.scss";
import { StoreContext } from "../../context/store-context";

const Nav = () => {
  const { checkout, loading } = React.useContext(StoreContext);
  const productsQuantity = checkout?.lineItems?.length;

  const {
    prismicSite,
    prismicContactInfo
  }: {
    prismicSite: PrismicSite;
    prismicContactInfo: PrismicContactInfo;
  } = useStaticQuery(query);

  const logoSrc = prismicSite?.data?.logo?.url;
  const logoAltText = prismicSite?.data?.logo?.alt;

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div>
      <div className={styles.nav__header}>
        <Container>
          <div className={styles.nav__header_content}>
            <div className={styles.nav__header_left}>
              <Link
                href={`tel:${prismicContactInfo.data.contact_phone}`}
                color="inherit"
                aria-label="Phone"
              >
                <Phone />
                {formatPhoneNumber(prismicContactInfo.data.contact_phone)}
              </Link>

              <Link
                href={`mailto:${prismicContactInfo.data.contact_email.text}`}
                color="inherit"
                aria-label="Email Detroit 75 Kitchen"
              >
                <Send />
                {prismicContactInfo.data.contact_email.text}
              </Link>
            </div>

            <div className={styles.nav__header_right}>
              <Link
                href={prismicContactInfo.data.facebook_url.url}
                rel="noopener noreferrer"
                target="_blank"
                color="inherit"
                aria-label="Detroit 75 Kitchen - Facebook"
              >
                <Facebook />
              </Link>

              <Link
                href={prismicContactInfo.data.twitter_url.url}
                rel="noopener noreferrer"
                target="_blank"
                color="inherit"
                aria-label="Detroit 75 Kitchen - Twitter"
              >
                <Twitter />
              </Link>

              <Link
                href={prismicContactInfo.data.instagram_url.url}
                rel="noopener noreferrer"
                target="_blank"
                color="inherit"
                aria-label="Detroit 75 Kitchen - Instagram"
              >
                <Instagram />
              </Link>
            </div>
          </div>
        </Container>
      </div>

      <nav className={styles.nav}>
        <Container className={styles.nav__toolbar}>
          <div className={styles.nav__logo}>
            <Link color="inherit" component={GatsbyLink} to="/">
              <img src={logoSrc} alt={logoAltText} />
            </Link>
          </div>

          <div className={styles.nav__actions}>
            <Hidden mdUp implementation="css">
              <Fab
                size="small"
                color="primary"
                className={styles.nav__menuButton}
                onClick={() => setMenuOpen(true)}
                aria-label="Open Menu"
              >
                <Menu />
              </Fab>
            </Hidden>

            <div className={styles.nav__links}>
              <ul>
                <li>
                  <Link
                    color="inherit"
                    component={GatsbyLink}
                    to="/"
                    className={styles.nav__link}
                    activeClassName={styles.nav__link_active}
                  >
                    Home
                  </Link>
                </li>
                {prismicSite?.data?.shop_enabled && (
                  <li>
                    <Link
                      color="inherit"
                      component={GatsbyLink}
                      to="/shop"
                      className={styles.nav__link}
                      activeClassName={styles.nav__link_active}
                    >
                      Shop
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    color="inherit"
                    component={GatsbyLink}
                    to="/story"
                    className={styles.nav__link}
                    activeClassName={styles.nav__link_active}
                  >
                    Story
                  </Link>
                </li>

                {prismicSite?.data?.merch_link_enabled && (
                  <li>
                    <Link
                      color="inherit"
                      component={GatsbyLink}
                      to={prismicSite?.data?.merch_link?.url}
                      className={styles.nav__link}
                      activeClassName={styles.nav__link_active}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {prismicSite?.data?.merch_link_title?.text}
                    </Link>
                  </li>
                )}

                {prismicSite?.data?.catering_link_enabled && (
                  <li>
                    <Link
                      color="inherit"
                      component={GatsbyLink}
                      to={prismicSite?.data?.catering_link?.text}
                      className={styles.nav__link}
                      activeClassName={styles.nav__link_active}
                    >
                      Catering
                    </Link>
                  </li>
                )}

                <li>
                  <Link
                    color="inherit"
                    component={GatsbyLink}
                    to="/press"
                    className={styles.nav__link}
                    activeClassName={styles.nav__link_active}
                  >
                    Press
                  </Link>
                </li>

                <li>
                  <Link
                    color="inherit"
                    component={GatsbyLink}
                    to="/contact"
                    className={styles.nav__link}
                    activeClassName={styles.nav__link_active}
                  >
                    Contact
                  </Link>
                </li>
                {prismicSite?.data?.shop_enabled && (
                  <li>
                    <Link
                      color="inherit"
                      component={GatsbyLink}
                      to="/cart"
                      className={styles.nav__link}
                      activeClassName={styles.nav__link_active}
                    >
                      <ShoppingCartOutlined />
                      {productsQuantity > 0 && (
                        <div className={styles.products_quantity}>
                          {productsQuantity}
                        </div>
                      )}
                    </Link>
                  </li>
                )}
                <li>
                  <Button
                    href={prismicSite?.data?.order_link?.url ?? null}
                    rel="noopener noreferrer"
                    target="_blank"
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={styles.order_button}
                  >
                    Order Now
                  </Button>
                </li>
              </ul>
            </div>

            <MobileNav
              open={menuOpen}
              handleClick={() => setMenuOpen(false)}
              logoSrc={logoSrc}
              logoAlt={logoAltText}
              orderUrl={prismicSite?.data?.order_link?.url ?? null}
              merchUrl={prismicSite?.data?.merch_link?.url ?? null}
              merchUrlTitle={prismicSite?.data?.merch_link_title?.text}
              merchLinkEnabled={prismicSite?.data?.merch_link_enabled}
              cateringUrl={prismicSite?.data?.catering_link?.text ?? null}
              shopEnabled={prismicSite?.data?.shop_enabled ?? null}
            />
          </div>
        </Container>
      </nav>
    </div>
  );
};

const query = graphql`
  query {
    prismicSite {
      data {
        logo {
          url
          alt
        }
        order_link {
          url
        }
        merch_link_enabled
        merch_link_title {
          text
        }
        merch_link {
          url
        }
        catering_link {
          text
        }
        catering_link_enabled
        shop_enabled
      }
    }
    prismicContactInfo {
      data {
        contact_email {
          text
        }
        contact_phone
        facebook_url {
          url
        }
        twitter_url {
          url
        }
        instagram_url {
          url
        }
      }
    }
  }
`;

export default Nav;
