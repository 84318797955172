import React, { useState } from "react";
import { FluidObject } from "gatsby-image";
import styles from "./ShopProductDetail.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { GlassMagnifier } from "react-image-magnifiers";

type ProductItemProps = {
  title: string;
  image: FluidObject;
  imageSrc: string;
  images: object;
};

type ProductNode = {
  node: ProductItemProps;
};

type ImageList = {
  productData: ProductNode;
  title: string;
  imageSrc: string;
};

const ShopProductGallery = ({ title, image, imageSrc, images }: ImageList) => {
  const [imageSource, setimageSource] = useState("");
  const changeimageSource = src => {
    setimageSource(src);
  };
  return (
    <div className={styles.dataImg}>
      <div className={styles.mainProductImg}>
        <GlassMagnifier
          imageSrc={imageSource || imageSrc}
          imageAlt={title}
          largeImageSrc={imageSource || imageSrc} // Optional
          magnifierSize="65%"
          magnifierBorderSize="10"
          allowOverflow={true}
          magnifierOffsetY="5"
          magnifierOffsetX="5"
        />
      </div>
      <div className={styles.smallImgbox}>
        {images.slice(-4).map((varImages, idx) => {
          if (idx !== 0) return;
          <img
            src={varImages.gatsbyImageData.images.fallback.src}
            alt={title}
            className={styles.smallImage}
            onClick={() =>
              changeimageSource(varImages.gatsbyImageData.images.fallback.src)
            }
          />;
        })}
      </div>
    </div>
  );
};

export default ShopProductGallery;
