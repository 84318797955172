import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

type SEOProps = {
  title?: string;
  description?: string;
  path?: string;
};

const SEO = (props: SEOProps) => {
  const {
    site: {
      siteMetadata: { title, description, url }
    }
  } = useStaticQuery(query);

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{props?.title ? `${props.title} | ${title}` : title}</title>
      <meta name="description" content={props?.description ?? description} />
      <meta
        property="og:title"
        content={props?.title ? `${props.title} | ${title}` : title}
      />
      <meta property="og:url" content={props?.path ? url + props.path : url} />
      <meta
        property="og:description"
        content={props?.description ?? description}
      />
    </Helmet>
  );
};

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`;

export default SEO;
